// @ts-nocheck
import FileCopy from "@mui/icons-material/FileCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Grid,
  IconButton,
  InputBase,
  Container,
  Typography,
  Tooltip
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { withRouter } from "react-router-dom";
import isURL from "validator/lib/isURL";
import StyledButton from "../design/components/StyledButton";
import StyledDialog from "../design/components/StyledDialog";
import StyledInput from "../design/components/StyledInput";
import API from "../Services/Api";
import { notify } from "./CustomNotifications";
import styles from "./styles/WebhookStyles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { isModuleDisabled } from "../Utils/AccessUtils";
import { modulesAccess } from "../Utils/Types";
import PageAccessContext from "../Utils/PageAccessContext";

const api = API.create();
class Webhook extends Component {
  static contextType = PageAccessContext;
  constructor(props: any) {
    super(props);

    this.state = {
      apiKey: props.publication.apiKey ? props.publication.apiKey : "",
      openCustomApiUrlDialog: false,
      subscribedUrl:
        props.publication.webhook && props.publication.webhook.subscribedUrl
          ? props.publication.webhook.subscribedUrl
          : "",
      unsubscribedUrl:
        props.publication.webhook && props.publication.webhook.unsubscribedUrl
          ? props.publication.webhook.unsubscribedUrl
          : "",
      publishedUrls: props.publication?.webhook?.publishedUrls || [],
      updateSubscriberUrl:
        props.publication.webhook &&
        props.publication.webhook.updateSubscriberUrl
          ? props.publication.webhook.updateSubscriberUrl
          : "",
      updateProjectUrl:
        props.publication.webhook && props.publication.webhook.updateProjectUrl
          ? props.publication.webhook.updateProjectUrl
          : "",
      showConfirmationDialog: false
    };
  }

  componentDidMount = () => {
    let { publication } = this.props;
    if (!publication.apiKey) {
      this.refreshToken();
    }
  };

  refreshToken = () => {
    api.refreshToken(this.props.publication._id, (res: any) => {
      if (res.status === 200) {
        this.setState({ apiKey: res.data, showConfirmationDialog: false });
      }
    });
  };

  connectToCustomApi = () => {
    this.setState({ openCustomApiUrlDialog: true });
  };

  saveCustomApiUrl = (event: any) => {
    let {
      unsubscribedUrl,

      subscribedUrl,

      publishedUrls,

      updateSubscriberUrl,

      updateProjectUrl
    } = this.state;

    if (unsubscribedUrl && !isURL(unsubscribedUrl)) {
      notify.show("Please enter valid unsubscribed url", "error");
      return;
    }

    if (subscribedUrl && !isURL(subscribedUrl)) {
      notify.show("Please enter valid subscribed url", "error");
      return;
    }

    if (
      publishedUrls.length &&
      publishedUrls.some((publishedUrl: any) => !isURL(publishedUrl))
    ) {
      notify.show("Please enter valid publish url", "error");
      return;
    }

    if (updateSubscriberUrl && !isURL(updateSubscriberUrl)) {
      notify.show("Please enter valid subscriber details update url", "error");
      return;
    }
    if (updateProjectUrl && !isURL(updateProjectUrl)) {
      notify.show("Please enter valid create/update project url", "error");
      return;
    }

    api.saveWebhookUrls(
      this.props.publication._id,
      unsubscribedUrl,
      subscribedUrl,
      publishedUrls,
      updateSubscriberUrl,
      updateProjectUrl,
      (res: any) => {
        if (res.status === 200) {
          this.setState({ openCustomApiUrlDialog: false }, () => {
            notify.show("Saved", "success", 3000);

            if (!this.state.apiKey) {
              this.setState({ apiKey: res.data });
            }
          });
        } else {
          notify.show(
            "We couldn't save your webhooks endpoints",
            "error",
            3000
          );
        }
      }
    );
  };

  addWebhookEndpoint = () => {
    let { publishedUrls } = this.state;

    publishedUrls.push("");
    this.setState({
      publishedUrls
    });
  };

  saveWebhookPublishUrl = (event: any, index: any) => {
    let { publishedUrls } = this.state;

    publishedUrls[index] = event.target.value.trim();

    this.setState({
      publishedUrls
    });
  };

  deleteWebhookPublishUrl = (index: any) => {
    let { publishedUrls } = this.state;

    publishedUrls.splice(index, 1);

    this.setState({
      publishedUrls
    });
  };

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cancelDialog = () => {
    this.setState({ showConfirmationDialog: false });
  };

  refreshApiKey = () => {
    this.setState({
      showConfirmationDialog: true
    });
  };

  render() {
    const {
      apiKey,

      openCustomApiUrlDialog,

      unsubscribedUrl,

      subscribedUrl,

      publishedUrls,

      updateSubscriberUrl,

      updateProjectUrl,

      showConfirmationDialog
    } = this.state;

    const { classes, publication } = this.props;

    return (
      <Fragment>
        <Container className={classes.container}>
          <Typography variant="h400" paragraph>
            API and Webhooks
          </Typography>

          <Grid
            container
            className={[classes.padding20, classes.width100].join(" ")}
          >
            <Grid item xs={12} className={classes.width100}>
              <Grid item container className={classes.embedCopyContainer}>
                <Grid item xs={12}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.marginTop10}
                  >
                    API Key
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    Use this in the api-key header to make API requests. Check
                    for this key in the api-key header to validate webhooks.
                  </Typography>
                </Grid>

                <Grid item container alignItems="center" xs={8}>
                  <InputBase
                    className={classes.apiKey}
                    value={apiKey ? apiKey : "Hit refresh to generate new keys"}
                    multiline={true}
                    contentEditable={false}
                    onFocus={(event) => event.target.select()}
                    fullWidth
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  container
                  alignItems="center"
                  style={{ paddingLeft: 10 }}
                >
                  <CopyToClipboard
                    onCopy={() => notify.show("Copied to clipboard", "success")}
                    text={apiKey}
                  >
                    <IconButton size="large">
                      <FileCopy />
                    </IconButton>
                  </CopyToClipboard>

                  <IconButton onClick={this.refreshApiKey} size="large">
                    <RefreshIcon />
                  </IconButton>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.marginTop20}
                  >
                    Webhook Endpoints
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.cardSubtitle}
                  >
                    We’ll send a POST request to these endpoints for events in
                    your workspace
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.marginBottom30}>
                <StyledButton
                  variant="textsecondary"
                  onClick={this.connectToCustomApi}
                >
                  Set up endpoints
                </StyledButton>
              </div>
            </Grid>
          </Grid>
        </Container>

        <StyledDialog
          open={openCustomApiUrlDialog}
          title="Webhook endpoints"
          body={
            <>
              <Typography variant="bodym" paragraph>
                Enter an endpoint to hit when a user unsubscribes
              </Typography>

              <StyledInput
                autoFocus
                className={classes.input}
                type="text"
                size="medium"
                name="unsubscribedUrl"
                placeholder="https://example.com/unsubscribe"
                value={unsubscribedUrl}
                onChange={this.handleChange}
              />

              <Typography
                variant="bodym"
                paragraph
                className={classes.marginTop20}
              >
                Enter an endpoint to hit when a user subscribes
              </Typography>

              <StyledInput
                className={classes.input}
                size="medium"
                name="subscribedUrl"
                placeholder="https://example.com/subscribe"
                type="text"
                value={subscribedUrl}
                onChange={this.handleChange}
              />

              <Typography
                variant="bodym"
                paragraph
                className={classes.marginTop20}
              >
                Enter an endpoint to hit when a subscriber details updated
              </Typography>

              <StyledInput
                className={classes.input}
                size="medium"
                name="updateSubscriberUrl"
                placeholder="https://example.com/update-subscriber"
                type="text"
                value={updateSubscriberUrl}
                onChange={this.handleChange}
              />

              {!isModuleDisabled(
                this.context,
                publication._id,
                modulesAccess.SEO
              ) && (
                <>
                  <Typography
                    variant="bodym"
                    paragraph
                    className={classes.marginTop20}
                  >
                    Enter an endpoint to hit when a post is published / updated
                    / deleted
                  </Typography>
                  {publishedUrls?.map((publishedUrl: any, index: any) => {
                    return (
                      <Grid container direction="row" key={index}>
                        <Grid item xs={11}>
                          <StyledInput
                            className={classes.input}
                            size="medium"
                            name="publishedUrl"
                            placeholder="https://example.com/publish"
                            type="text"
                            value={publishedUrl}
                            onChange={(event: any) =>
                              this.saveWebhookPublishUrl(event, index)
                            }
                          />
                        </Grid>

                        <Grid item xs={1}>
                          <span className={classes.justifyContent}>
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() =>
                                  this.deleteWebhookPublishUrl(index)
                                }
                                size="large"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          </span>
                        </Grid>
                      </Grid>
                    );
                  })}

                  <StyledButton
                    variant="textprimary"
                    style={{ marginTop: 16 }}
                    onClick={this.addWebhookEndpoint}
                    startIcon={<AddCircleIcon />}
                  >
                    Add webhook endpoint
                  </StyledButton>
                </>
              )}

              <Typography
                variant="bodym"
                paragraph
                className={classes.marginTop20}
              >
                Enter an endpoint to hit when project is created/updated
              </Typography>

              <StyledInput
                className={classes.input}
                size="medium"
                name="updateProjectUrl"
                placeholder="https://example.com/project/update"
                type="text"
                value={updateProjectUrl}
                onChange={this.handleChange}
              />
            </>
          }
          successButtonName="Save"
          successCallback={this.saveCustomApiUrl}
          cancelCallback={() => {
            this.setState({ openCustomApiUrlDialog: false });
          }}
        />

        <StyledDialog
          open={showConfirmationDialog}
          title="Are you sure?"
          body="You'll have to replace your API key with the new one if you proceed"
          successButtonName="Yes"
          successCallback={this.refreshToken}
          cancelCallback={this.cancelDialog}
        />
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Webhook));
