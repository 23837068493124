import React, { useState, useEffect, SyntheticEvent } from "react";
import { Autocomplete, Grid, Typography } from "@mui/material";
import StyledInput from "../design/components/StyledInput";
import StyledCheckbox from "../design/components/StyledCheckbox";
import { Box } from "@mui/system";
import { linkedInOptionTypes } from "../Utils/Types";
import { space } from "../Config/theme";
import StyledAvatar from "../design/components/StyledAvatar";
interface LinkedInAutoCompleteProps {
  onChange: (selected: GroupOption[], optionIndex: number) => void;
  optionIndex: number;
  value?: GroupOption[];
  disabled?: boolean;
  accounts: GroupOption[];
  organizations: GroupOption[] | undefined;
  groups: string[];
}

interface GroupOption {
  connected?: boolean;
  userId: string;
  name: string;
  group?: string[];
  profilePicture?: string;
  type: string; // "ACCOUNTS" | "ORGANIZATIONS" | "GROUPS" | "ALL"
}

function LinkedInAutoComplete({
  onChange,
  optionIndex,
  value,
  disabled,
  accounts,
  organizations,
  groups,
  ...others
}: LinkedInAutoCompleteProps) {
  const getFormattedGroupDetails = () => {
    return groups.map((group: string) => {
      return {
        name: group,
        type: linkedInOptionTypes.GROUPS,
        group: [],
        userId: group
      };
    });
  };

  const getFormattedAccountDetails = (
    linkedInAccounts: Array<GroupOption> = [],
    type: string = ""
  ): GroupOption[] => {
    if (!linkedInAccounts) {
      return [];
    }

    return linkedInAccounts
      .filter((eachAccount) => eachAccount?.connected)
      .map((eachAccount: GroupOption) => {
        return {
          userId: eachAccount.userId,
          name: eachAccount.name,
          group: eachAccount.group,
          profilePicture: eachAccount?.profilePicture || "",
          connected: eachAccount.connected,
          type: type
        };
      });
  };

  const getAllAccounts = () => {
    let options = [...accounts];
    if (organizations) {
      options.push(...organizations);
    }
    return options;
  };

  const getOptions = () => {
    let options = [
      {
        name: linkedInOptionTypes.ALL,
        userId: linkedInOptionTypes.ALL,
        type: ""
      },
      ...getFormattedGroupDetails(),
      ...getFormattedAccountDetails(accounts, linkedInOptionTypes.ACCOUNTS)
    ];
    if (organizations) {
      options.push(
        ...getFormattedAccountDetails(
          organizations,
          linkedInOptionTypes.ORGANIZATIONS
        )
      );
    }
    return options;
  };

  const groupsData = getFormattedGroupDetails();
  const allAccountsAndOrgs = getAllAccounts();
  const options = getOptions();

  const currentSelected = getFormattedAccountDetails(value);
  const isAllSelected = currentSelected.length === allAccountsAndOrgs.length;

  //Run when selected data value is updated
  const updateSelectedData = (newSelectedData: GroupOption[]) => {
    onChange(newSelectedData, optionIndex);
  };

  /**Old state of checkbox is checked to enable onclick of box,
  hence the negated value is used to check if the checkbox is currently checked in all onclick functions**/

  const accountSelect = (
    newSelected: GroupOption | GroupOption[],
    isChecked: boolean
  ) => {
    newSelected = Array.isArray(newSelected) ? newSelected : [newSelected];

    if (newSelected.length === 0) {
      updateSelectedData([]);
      return;
    }
    if (!isChecked) {
      // Create a Set to store unique selected values
      let uniqueSelected = new Set([...currentSelected, ...newSelected]);
      updateSelectedData(Array.from(uniqueSelected));
      // Update selected state with unique values from the Set
    } else {
      let valueToRemove = newSelected[0];
      let updatedSelected = currentSelected?.filter(
        (item) => item.userId !== valueToRemove.userId
      );
      updateSelectedData(updatedSelected);
    }
  };

  //Only used for clearing autocomplete and removing options by using chip delete
  const uncheckOrClearData = (
    event: SyntheticEvent<Element, Event>,
    newInputValue: GroupOption[],
    reason: string
  ) => {
    if (reason === "clear") {
      updateSelectedData([]);
      return;
    }
    if (reason === "removeOption") {
      updateSelectedData(newInputValue);
      return;
    }
  };

  const allOptionChange = (isChecked: boolean) => {
    updateSelectedData(!isChecked ? allAccountsAndOrgs : []);
  };

  const groupOptionChange = (groupId: string, isChecked: boolean) => {
    const relatedAccounts = accounts?.filter((account: GroupOption) =>
      account?.group?.includes(groupId)
    );

    // Check if all accounts related to the group are selected
    const areAllRelatedAccountsSelected = relatedAccounts.every(
      (account: GroupOption) =>
        currentSelected.some((item) => item.userId === account.userId)
    );

    let updatedSelected = currentSelected?.filter(
      (item: GroupOption) => item.userId !== groupId || isChecked
    );

    if (!isChecked) {
      // Extract unique account IDs from related accounts

      // Filter for accounts not already selected
      const accountsToAdd = relatedAccounts.filter(
        (account: GroupOption) =>
          !currentSelected.some(
            (item: GroupOption) => item.userId === account.userId
          )
      );
      // Add unique accounts to selected state
      updatedSelected.push(...accountsToAdd);
    } else if (areAllRelatedAccountsSelected) {
      // Uncheck group if all related accounts are deselected
      const accountsToRemove = relatedAccounts.map(
        (account: GroupOption) => account.userId
      );
      updatedSelected = updatedSelected?.filter(
        (item: GroupOption) => !accountsToRemove.includes(item.userId)
      );
    }

    updateSelectedData(updatedSelected);
  };

  const isGroupSelected = (groupId: string, selectedData: GroupOption[]) => {
    // Filter accounts directly associated with the group using `groupIds`
    const relatedAccounts = accounts?.filter(
      (account: GroupOption) => account.group && account.group.includes(groupId)
    );
    if (!relatedAccounts.length) {
      return false;
    }
    // Return true if the group is selected and all related accounts are selected
    return relatedAccounts.every((account: GroupOption) =>
      selectedData.some((item: GroupOption) => item.userId === account.userId)
    );
  };

  const isAccountSelected = (accountId: string) =>
    currentSelected.some((item: GroupOption) => item.userId === accountId);

  const isSelected = (option: GroupOption) => {
    if (option.name === linkedInOptionTypes.ALL) {
      return isAllSelected;
    }
    if (option.type === linkedInOptionTypes.GROUPS) {
      return isGroupSelected(option.name, currentSelected);
    }
    return isAccountSelected(option.userId);
  };

  const optionSelect = (option: GroupOption) => {
    if (option.name === linkedInOptionTypes.ALL) {
      return allOptionChange(isAllSelected);
    }
    if (option.type === linkedInOptionTypes.GROUPS) {
      return groupOptionChange(
        option.name,
        isGroupSelected(option.name, currentSelected)
      );
    }
    return accountSelect(option, isAccountSelected(option.userId));
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      disableCloseOnSelect
      value={currentSelected}
      onChange={uncheckOrClearData}
      options={options}
      disabled={disabled}
      getOptionLabel={(option: GroupOption) => option.name}
      renderInput={(params) => (
        <StyledInput {...params} size="small" variant="outlined" />
      )}
      groupBy={(option: GroupOption) => option.type}
      isOptionEqualToValue={(option: GroupOption, value: GroupOption) => {
        return option.userId === value.userId;
      }}
      renderOption={(props, option: GroupOption, index) => {
        return (
          <Box
            component={"li"}
            key={option.userId}
            {...props}
            onClick={() => optionSelect(option)}
          >
            <StyledCheckbox checked={isSelected(option)} />
            <Grid container direction="row" alignItems="center">
              {[
                linkedInOptionTypes.ACCOUNTS,
                linkedInOptionTypes.ORGANIZATIONS
              ].includes(option.type) && (
                <StyledAvatar
                  alt={option.name}
                  variant="circular"
                  style={{
                    objectFit: "cover",
                    backgroundColor: "transparent"
                  }}
                  src={option?.profilePicture || ""}
                />
              )}
              <Typography variant="bodym" style={{ marginLeft: space.MEDIUM }}>
                {option?.name}
              </Typography>
            </Grid>
          </Box>
        );
      }}
    />
  );
}

export default LinkedInAutoComplete;
