import {
  CircularProgress,
  FormControl,
  Grid,
  ListSubheader,
  Typography
} from "@mui/material";
import Api from "../Services/Api";
import { channelTypes } from "../Utils/Types";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import { space } from "../Config/theme";
import React, { FC, useEffect, useRef, useState } from "react";
import colors, { designColors } from "../Themes/Colors";
import StyledPopper from "../design/components/StyledPopper";
import LinkedInPreview from "./LinkedInPreview";
import { Sample } from "./AIGeneratePost";
import { getShortVersionOfText } from "../Utils/UserUtils";
import StyledButton from "../design/components/StyledButton";

const api = Api.create();

type LinkedInTemplateDropdownProps = {
  publicationId: string;
  label?: string;
  description?: string;
  type?: string;
  onChange: (event: any, samples: Sample[]) => void;
  linkedInAccountName?: string;
  linkedInAccountPicture?: string;
  linkedInBody?: string;
  initialSelectedSampleId?: string;
  initialSelectedSample?: string;
  initialSamples: Sample[];
  initialPreviewSample?: string;
  initialOpen?: boolean;
  initialAnchorEl?: any;
  initialTemplateDropdownRef?: any;
  showSuggestTemplateButton?: boolean;
  disableSuggestTemplateButton?: boolean;
  loadingSuggestedTemplates?: boolean;
  suggestLinkedinTemplatesFromPost?: () => void;
  suggestedSampleIds: string[];
  notFetchAllSamples?: boolean;
};

const LinkedInTemplateDropdown: FC<LinkedInTemplateDropdownProps> = ({
  publicationId,
  label,
  description,
  type,
  onChange,
  linkedInAccountName,
  linkedInAccountPicture,
  linkedInBody = "",
  initialSelectedSampleId = "",
  initialSelectedSample = "",
  initialSamples = [],
  initialPreviewSample = "",
  initialOpen = false,
  initialAnchorEl = null,
  initialTemplateDropdownRef = null,
  showSuggestTemplateButton = false,
  disableSuggestTemplateButton = false,
  loadingSuggestedTemplates = false,
  suggestLinkedinTemplatesFromPost = () => {},
  suggestedSampleIds = [],
  notFetchAllSamples
}) => {
  let [selectedSampleId, setSelectedSampleId] = useState(
    initialSelectedSampleId
  );
  let [selectedSample, setSelectedSample] = useState(initialSelectedSample);
  let [selectedSampleLabel, setSelectedSampleLabel] = useState("");
  let [previewSample, setPreviewSample] = useState(initialPreviewSample);
  let [samples, setSamples] = useState(initialSamples);
  let [open, setOpen] = useState(initialOpen);
  let [anchorEl, setAnchorEl] = useState(initialAnchorEl);
  let templateDropdownRef = useRef(initialTemplateDropdownRef);

  useEffect(() => {
    if (initialSelectedSampleId === selectedSampleId) {
      return;
    }
    setSelectedSampleId(initialSelectedSampleId);
  }, [initialSelectedSampleId]);

  useEffect(() => {
    if (initialSelectedSample === selectedSample) {
      return;
    }
    setSelectedSample(initialSelectedSample);
  }, [initialSelectedSample]);

  useEffect(() => {
    let selected = samples.find((s) => s._id === selectedSampleId);
    setSelectedSampleLabel(selected?.label || "");
  }, [selectedSampleId, samples]);

  useEffect(() => {
    getAllSamples();
  }, []);

  const onPopoverClose = () => {
    setOpen(false);
  };

  const getAllSamples = () => {
    if (notFetchAllSamples) return;

    let channelType = type || channelTypes.LINKEDIN;

    api.getSamples(publicationId, channelType, (res: any) => {
      if (res.status === 200) {
        let allSamples = res?.data || [];
        setSamples(allSamples);

        if (selectedSample) {
          setSelectedSampleId(
            allSamples
              ?.find(
                (sample: Sample) => sample?.sample === selectedSample || ""
              )
              ?._id?.toString() || ""
          );
        }
      }
    });
  };

  const openLinkedInPreview = (event: any, sampleId: string) => {
    setAnchorEl(templateDropdownRef.current);
    setOpen(true);
    setPreviewSample(
      samples.find((sample: Sample) => sample?._id?.toString() === sampleId)
        ?.sample || ""
    );
  };

  const sampleRenderer = (sample: Sample, index: number) => {
    return (
      <StyledMenuItem
        key={sample?._id?.toString() || index}
        value={sample?._id?.toString()}
        style={{
          width: "100%"
        }}
        onMouseOver={(e: any) => openLinkedInPreview(e, sample?._id)}
      >
        <Typography
          style={{
            marginLeft: space.SMALL,
            verticalAlign: "bottom",
            margin: space.XS,
            maxWidth: "480px",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
          variant="bodym"
        >
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: 650
            }}
          >
            {sample?.label || sample?.sample}
          </div>
        </Typography>
      </StyledMenuItem>
    );
  };

  const handleSuggestedSamples = () => {
    const suggestedSamples: Sample[] = [];
    const allSamples: Sample[] = [];

    if (!suggestedSampleIds || suggestedSampleIds.length < 1) {
      return [suggestedSamples, samples];
    }

    const suggestedSampleIdsMap: { [id: string]: boolean } = {};
    suggestedSampleIds.forEach((i) => {
      suggestedSampleIdsMap[i] = true;
    });

    samples.forEach((sample) => {
      const sampleId = sample?._id;
      if (sampleId && suggestedSampleIdsMap[sampleId]) {
        suggestedSamples.push(sample);
      } else {
        allSamples.push(sample);
      }
    });

    return [suggestedSamples, allSamples];
  };

  const defaultSample: Sample = {
    _id: "",
    label: "No template",
    sample: "",
    publication: "",
    channel: ""
  };

  const [suggestedSamples, allSamples] = handleSuggestedSamples();

  return (
    <>
      <div>
        <div>
          {label && (
            <Typography
              variant="bodyl"
              style={{ marginBottom: space.MEDIUM, marginTop: space.MEDIUM }}
            >
              {label}
            </Typography>
          )}
          {description && (
            <>
              <Grid
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: space.MEDIUM
                }}
              >
                <Typography
                  style={{
                    color: colors.fontSecondary,
                    fontSize: 14,
                    fontFamily: "Poppins"
                  }}
                >
                  {description}
                </Typography>
                {showSuggestTemplateButton && (
                  <Grid
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {loadingSuggestedTemplates && (
                      <CircularProgress
                        size={14}
                        style={{
                          color: designColors.primary[50],
                          marginRight: space.XS
                        }}
                      />
                    )}
                    <StyledButton
                      size="small"
                      variant="textprimary"
                      disabled={
                        disableSuggestTemplateButton ||
                        loadingSuggestedTemplates
                      }
                      onClick={suggestLinkedinTemplatesFromPost}
                    >
                      Suggest Templates
                    </StyledButton>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </div>
        <div>
          <FormControl
            variant="outlined"
            style={{
              display: "block",
              width: "100%"
            }}
            ref={templateDropdownRef}
            fullWidth
          >
            <StyledSelect
              name={"sample"}
              value={selectedSampleId}
              defaultValue={selectedSampleId}
              renderValue={() => selectedSampleLabel}
              onChange={(event: any) => {
                setSelectedSampleId(event.target.value);
                setSelectedSample(
                  samples.find(
                    (sample: Sample) =>
                      sample?._id?.toString() === event.target.value
                  )?.sample || ""
                );
                if (onChange) {
                  onChange(event, samples);
                }
                onPopoverClose();
                setOpen(false);
              }}
              placeholder="Choose a sample"
              style={{
                backgroundColor: colors.inputBackground,
                border: "none",
                marginTop: 0,
                padding: 0,
                width: "100%",
                color: colors.fontPrimary
              }}
              disabled={!Boolean(samples.length)}
            >
              {[defaultSample].map(sampleRenderer)}

              {suggestedSamples.length > 0 && [
                <ListSubheader key={"suggested-templates"}>
                  Suggested
                </ListSubheader>,
                ...suggestedSamples.map(sampleRenderer),
                <ListSubheader key={"all-templates"}>
                  All Templates
                </ListSubheader>
              ]}
              {allSamples.map(sampleRenderer)}
            </StyledSelect>
          </FormControl>
        </div>
      </div>
      <StyledPopper
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        width={400}
        zIndex={2000}
        isTransparent={true}
        placement="right-start"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        clickAwayCallback={onPopoverClose}
        disableRestoreFocus
        body={
          <LinkedInPreview
            linkedInPost={{
              accountName: linkedInAccountName,
              accountPicture: linkedInAccountPicture,
              body: previewSample || linkedInBody
            }}
            isTemplatePreview={true}
          />
        }
      />
    </>
  );
};

export default LinkedInTemplateDropdown;
