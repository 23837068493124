// @ts-nocheck
import firebase from "firebase";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  generateRandomId,
  getDatePath,
  cleanFileNameForUrl,
  removeImageExtensionFromName
} from "./UserUtils";
import { compressImage } from "./ImageUtils";
var mime = require("mime-types");

const CDN = "https://cdn.letterdrop.co";
const IMAGE_FOLDER = "images/";

// This regular expression can be used to match a string that consists solely of alphanumeric characters, underscores, periods, and hyphens
export const IMAGE_NAME_REGEX = /^[A-Za-z0-9_.-]*$/g;
const SPACE_REGEX = / /g;
const INVALID_CHAR_REGEX = /[^\w\d.-_]/g;

// Same as in letterdrop-nextjs.
export async function uploadImage(file: any, publicationDomain = "") {
  if (file) {
    // remove spaces from file name
    let fileName = file.name
      .replace(SPACE_REGEX, "")
      .replace(INVALID_CHAR_REGEX, "");

    fileName = fileName || generateRandomId(10);

    fileName = cleanFileNameForUrl(fileName, IMAGE_NAME_REGEX);
    // add workspace and date path like 2022/04/02/acme-inc/
    let datePath = getDatePath(publicationDomain);

    try {
      await firebase
        .storage()
        .ref(IMAGE_FOLDER + datePath)
        .child(fileName)
        .put(file, {
          cacheControl: "public, max-age=31536000"
        });

      let url = CDN + "/" + IMAGE_FOLDER + datePath + "/" + fileName;
      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

export async function uploadBlobImage(
  file: any,
  publicationDomain: string = "",
  fileName: string = "",
  convertToWebp: boolean = false
) {
  if (file) {
    var blob = await blobFromURL(file);
    if (convertToWebp) {
      blob = await compressImage(blob, "image/webp");
    }
    fileName = fileName || generateRandomId(10);

    let datePath = getDatePath(publicationDomain);

    fileName = cleanFileNameForUrl(fileName, IMAGE_NAME_REGEX);

    if (blob.type) {
      let extension = mime.extension(blob.type);
      if (extension) {
        fileName = removeImageExtensionFromName(fileName);
        fileName += "." + extension;
      }
    }
    try {
      await firebase
        .storage()
        .ref(IMAGE_FOLDER + datePath)
        .child(fileName)
        .put(blob, {
          cacheControl: "public, max-age=31536000",

          type: blob.type
        });

      let url = CDN + "/" + IMAGE_FOLDER + datePath + "/" + fileName;
      return url;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

export async function uploadBlobUrlImageForEditor(
  file: any,
  helperId: any,
  publicationDomain = ""
) {
  if (file) {
    var blob = await blobFromURL(file);
    var fileName = generateRandomId(10);
    fileName = cleanFileNameForUrl(fileName, IMAGE_NAME_REGEX);

    let datePath = getDatePath(publicationDomain);

    if (blob.type) {
      let extension = mime.extension(blob.type);
      if (extension) {
        fileName += "." + extension;
      }
    }
    try {
      await firebase
        .storage()
        .ref(IMAGE_FOLDER + datePath)
        .child(fileName)
        .put(blob, {
          cacheControl: "public, max-age=31536000"
        });

      let url = CDN + "/" + IMAGE_FOLDER + datePath + "/" + fileName;

      return {
        url,
        helperId
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

async function blobFromURL(url: any) {
  let blob = await fetch(url).then((r) => r.blob());
  return blob;
}

export async function uploadCSVFile(file: any, prefix = "emails") {
  var fileName = `${prefix}_${uuidv4()}_${moment().format("MM-DD-YYYY")}.csv`;
  try {
    var snapshot = null;
    if (typeof file === "string") {
      var metadata = {
        contentType: "text/csv"
      };

      snapshot = await firebase
        .app()
        .storage("gs://zmtvapp.appspot.com")
        .ref()
        .child(fileName)
        .putString(file, "raw", metadata);
    } else {
      snapshot = await firebase
        .app()
        .storage("gs://zmtvapp.appspot.com")
        .ref()
        .child(fileName)
        .put(file);
    }

    let url = await snapshot.ref.getDownloadURL();
    return {
      url,
      fileName
    };
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function uploadMp4Video(
  video: any,
  publicationDomain: any,
  changeProgress: any,
  filename = ""
) {
  try {
    // remove spaces from file name
    let fileName = video.name
      .replace(SPACE_REGEX, "")
      .replace(INVALID_CHAR_REGEX, "");
    fileName = fileName || generateRandomId(10);
    let datePath = getDatePath(publicationDomain);

    let storageRef = firebase
      .app()
      .storage()
      .ref("video/" + datePath)
      .child(fileName);

    let uploadTask = storageRef.put(video);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          let progress = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          changeProgress?.(progress, video);
        },
        (error) => {
          console.error(`Upload error for file ${fileName}:`, error);
        },
        () => {
          changeProgress?.(0);
          // Upload completed successfully, now we can get the download URL
          resolve(CDN + "/video/" + datePath + "/" + fileName);
        }
      );
    });
  } catch (error) {
    return null;
  }
}

export async function uploadMp3Audio(
  audio: any,
  publicationDomain: string,
  changeProgress: any,
  filename: string = ""
) {
  try {
    // remove spaces from file name
    let fileName = audio.name
      .replace(SPACE_REGEX, "")
      .replace(INVALID_CHAR_REGEX, "");
    // append a unique id to the file name
    fileName = fileName || generateRandomId(10);
    let datePath = getDatePath(publicationDomain);

    let storageRef = firebase
      .app()
      .storage()
      .ref("audio/" + datePath)
      .child(fileName);

    let uploadTask = storageRef.put(audio);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          let progress = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          changeProgress?.(progress, audio);
        },
        (error) => {
          console.error(`Upload error for file ${fileName}:`, error);
        },
        () => {
          changeProgress?.(0);
          // Upload completed successfully, now we can get the download URL
          resolve(CDN + "/audio/" + datePath + "/" + fileName);
        }
      );
    });
  } catch (error) {
    return null;
  }
}

//To upload doc file other than image and videos
export async function uploadFile(
  file: any,
  publicationDomain: any,
  changeProgress: any
) {
  try {
    // remove spaces from file name
    let fileName = file.name
      .replace(SPACE_REGEX, "")
      .replace(INVALID_CHAR_REGEX, "");

    fileName = fileName || generateRandomId(10);

    let datePath = getDatePath(publicationDomain);

    let storageRef = firebase
      .app()
      .storage()
      .ref("file/" + datePath)
      .child(fileName);

    let uploadTask = storageRef.put(file);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          let progress = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          changeProgress?.(progress);
        },
        (error) => {
          console.error(`Upload error for file ${fileName}:`, error);
        },
        () => {
          changeProgress?.(0);
          resolve(CDN + "/file/" + datePath + "/" + fileName);
        }
      );
    });
  } catch (error) {
    return null;
  }
}

// Input - any docType file Array from Input[file type]
// output - array of file objects with name, type, cdn url and status
export async function uploadFiles(
  files: any,
  publicationDomain = "",
  changeProgress = null
) {
  let urls = [];
  for (let file of files) {
    let fileData = {};

    fileData.name = file.name;

    fileData.type = file.type;

    if (file.url) {
      urls.push(file);
      continue;
    }

    if (!file.status) {
      if (file.type.includes("image")) {
        fileData.url = await uploadImage(file, publicationDomain);
      } else if (file.type.includes("video")) {
        fileData.url = await uploadMp4Video(
          file,
          publicationDomain,
          changeProgress,
          file.name
        );
      } else {
        fileData.url = await uploadFile(file, publicationDomain);
      }
    } else {
      fileData.url = file.url;
    }

    fileData.status = "uploaded";
    urls.push(fileData);
  }
  return urls;
}
