import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";

import StyledDialog from "../design/components/StyledDialog";

import API from "../Services/Api";
import { Divider, Typography } from "@mui/material";
import { space } from "../Config/theme";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import { notify } from "./CustomNotifications";

const api = API.create();

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: space.MEDIUM
  },
  formField: {
    marginTop: space.SMALL
  },
  divider: {
    marginTop: space.MEDIUM
  }
}));

export default function HusbpotCRMConfiguration({
  open,
  handleClose,
  publicationId
}: any) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [errorDetails, setError] = useState({
    error: false,
    message: ""
  });
  const [selectionError, setSelectionError] = useState(false);
  const [pipeline, setPipeline] = useState("");
  const [pipelines, setPipelines] = useState<
    Array<{
      id: string;
      label: string;
      stages: {
        label: string;
        id: string;
      }[];
    }>
  >([]);
  const [stages, setStages] = useState<{ id: string; label: string }[]>([]);
  const [closedWonStage, setClosedWonStage] = useState<string>("");
  const [closedLostStage, setClosedLostStage] = useState<string>("");

  const load = () => {
    api.getPipelinesFromHubspot(publicationId, (res) => {
      setLoading(false);
      if (res.status === 200) {
        setPipeline(res.data?.current);
        setPipelines(res.data?.available);
        const stages = res.data?.available?.find(
          (item: any) => item.id === res.data?.current
        )?.stages;
        setStages(stages || []);
        setClosedWonStage(
          stages.find(
            (stage: any) => stage?.label === res.data?.dealStages?.closedwon
          )?.id
        );
        setClosedLostStage(
          stages.find(
            (stage: any) => stage?.label === res.data?.dealStages?.closedlost
          )?.id
        );
      } else {
        setError({
          error: true,
          message: "Failed to fetch pipelines from Hubspot"
        });
      }
    });
  };

  useEffect(load, []);

  const handleChangePipeline = (event: any) => {
    setPipeline(event.target.value);
    setStages(
      pipelines.find((item) => item.id === event.target.value)?.stages || []
    );
    setSelectionError(false);
  };

  const handleSave = () => {
    const closedwon = stages.find((item) => item.id === closedWonStage)?.label;
    const closedlost = stages.find(
      (item) => item.id === closedLostStage
    )?.label;
    if (
      !pipeline ||
      !pipelines.find((item) => item.id === pipeline) ||
      !closedwon ||
      !closedlost
    ) {
      setSelectionError(true);
      return;
    }
    const dealStages = {
      closedwon,
      closedlost
    };
    api.setHubspotPipeline(publicationId, pipeline, dealStages, (res) => {
      if (res.status === 200) {
        notify.show("Details saved", "success");
        handleClose();
      } else {
        notify.show("Failed to save Details. Please try again later.", "error");
      }
    });
  };

  return (
    <>
      <StyledDialog
        open={open}
        title="Configure Hubspot CRM"
        body={
          <>
            {loading && <Skeleton width="100%" height={50} />}
            {!loading && errorDetails.error && (
              <Alert severity="error">{errorDetails.message}</Alert>
            )}

            {!loading && !errorDetails.error && (
              <>
                <FormControl className={classes.formControl} fullWidth>
                  <Typography variant="bodym">
                    Select a pipeline that you want to use for content
                    attribution
                  </Typography>
                  <StyledSelect
                    onChange={handleChangePipeline}
                    placeholder="Select Pipeline"
                    value={pipeline}
                    className={classes.formField}
                  >
                    {pipelines.map((item) => (
                      <StyledMenuItem key={item.id} value={item.id}>
                        {item.label}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                <Divider className={classes.divider} />
                <FormControl className={classes.formControl} fullWidth>
                  <Typography variant="bodym">
                    Select a stage corresponds to "Closed/Won" Deals
                  </Typography>
                  <StyledSelect
                    onChange={(event: any) => {
                      setClosedWonStage(event.target.value);
                      setSelectionError(false);
                    }}
                    placeholder="Select Stage"
                    value={closedWonStage}
                    className={classes.formField}
                  >
                    {stages.map((item) => (
                      <StyledMenuItem key={item.id} value={item.id}>
                        {item.label}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <Typography variant="bodym">
                    Select a stage corresponds to "Closed/Lost" Deals
                  </Typography>
                  <StyledSelect
                    onChange={(event: any) => {
                      setClosedLostStage(event.target.value);
                      setSelectionError(false);
                    }}
                    placeholder="Select Stage"
                    value={closedLostStage}
                    className={classes.formField}
                  >
                    {stages.map((item) => (
                      <StyledMenuItem key={item.id} value={item.id}>
                        {item.label}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                {selectionError && (
                  <Alert
                    severity="warning"
                    style={{
                      marginTop: 10
                    }}
                  >
                    Select all the fields
                  </Alert>
                )}
              </>
            )}
          </>
        }
        successButtonName="Save"
        successCallback={handleSave}
        cancelButtonName="Close"
        cancelCallback={handleClose}
      />
    </>
  );
}
