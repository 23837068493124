import React from "react";
import { linkedinAutoLikesPostTimeTypes } from "../Utils/Types";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import { Typography } from "@mui/material";
import { space } from "../Config/theme";
import colors from "../Themes/Colors";

const autoLikeTimeGapOption = [
  {
    name: "First 30 minutes - use if selected accounts are your buyer persona",
    type: linkedinAutoLikesPostTimeTypes.IN_THIRTY_MINUTES
  },
  {
    name: "After 2 hours - use if selected accounts are sellers and execs",
    type: linkedinAutoLikesPostTimeTypes.AFTER_TWO_HOURS
  }
];

type Props = {
  value: string;
  label: string;
  labelStyle?: {
    [key: string]: string | number | undefined;
  };
  containerStyle?: {
    [key: string]: string | number | undefined;
  };
  onAutoLikeTimeConfigChange: (value: string) => void;
};

export default function AutoLikePostTimingSelect({
  value,
  label,
  labelStyle = {},
  containerStyle = {},
  onAutoLikeTimeConfigChange
}: Props) {
  return (
    <div style={{ ...(containerStyle || {}) }}>
      <Typography
        style={{
          color: colors.fontSecondary,
          marginBottom: space.MEDIUM,
          fontSize: 14,
          ...(labelStyle || {})
        }}
      >
        {label || "Likes will drop in"}
      </Typography>
      <StyledSelect
        value={value || ""}
        size="medium"
        fullWidth
        onChange={(event: any) =>
          onAutoLikeTimeConfigChange(event.target.value)
        }
      >
        {autoLikeTimeGapOption.map((option) => {
          return (
            <StyledMenuItem
              key={option.type}
              value={option.type}
              style={{ display: "block" }}
            >
              {option.name}
            </StyledMenuItem>
          );
        })}
      </StyledSelect>
    </div>
  );
}
