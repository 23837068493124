import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";

import StyledDialog from "../design/components/StyledDialog";

import API from "../Services/Api";
import { Divider, Typography } from "@mui/material";
import { space } from "../Config/theme";
import StyledSelect, {
  StyledMenuItem
} from "../design/components/StyledSelect";
import { notify } from "./CustomNotifications";

const api = API.create();

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: space.MEDIUM
  },
  formField: {
    marginTop: space.SMALL
  },
  divider: {
    marginTop: space.MEDIUM
  }
}));

export default function SalesforceCRMConfiguration({
  open,
  handleClose,
  publicationId
}: any) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [errorDetails, setError] = useState({
    error: false,
    message: ""
  });
  const [selectionError, setSelectionError] = useState(false);
  const [pipelineField, setPipelineField] = useState("");
  const [pipelineFieldIndex, setPipelineFieldIndex] = useState<number>(-1);
  const [crmPipeline, setCrmPipeline] = useState("");
  const [pipelineFields, setPipelineFields] = useState<
    Array<{
      id: string;
      label: string;
      values: { id: string; label: string }[];
    }>
  >([]);
  const [stages, setStages] = useState<{ id: string; label: string }[]>([]);
  const [closedWonStage, setClosedWonStage] = useState<string>("");
  const [closedLostStage, setClosedLostStage] = useState<string>("");

  const load = () => {
    api.getPipelineFieldsFromSalesforce(publicationId, (res) => {
      setLoading(false);
      if (res.status === 200) {
        const pipelineFields = [];
        let stages = [];
        for (let field of res.data?.pipelineFields) {
          if (field.id === "StageName") {
            stages = field?.values || [];
          } else {
            pipelineFields.push(field);
          }
        }
        for (let index = 0; index < pipelineFields.length; index++) {
          const field = pipelineFields[index];
          if (field.id === res.data?.pipelineField) {
            setPipelineFieldIndex(index);
          }
        }
        setStages(stages);
        setPipelineFields(pipelineFields);
        setPipelineField(res.data?.pipelineField);
        if (
          pipelineFields.find((item) => item.id === res.data?.pipelineField)
        ) {
          setCrmPipeline(res.data?.crmPipeline);
        }
        if (
          stages.find(
            (item: any) => item.id === res.data?.dealStages?.closedwon
          )
        ) {
          setClosedWonStage(res.data?.dealStages?.closedwon);
        }
        if (
          stages.find(
            (item: any) => item.id === res.data?.dealStages?.closedlost
          )
        ) {
          setClosedLostStage(res.data?.dealStages?.closedlost);
        }
      } else {
        setError({
          error: true,
          message: "Failed to fetch data from Salesforce"
        });
      }
    });
  };

  useEffect(load, []);

  const handleSave = () => {
    if (
      !pipelineField ||
      !pipelineFields.find((item) => item.id === pipelineField) ||
      !closedWonStage ||
      !stages.find((item) => item.id === closedWonStage) ||
      !closedLostStage ||
      !stages.find((item) => item.id === closedLostStage) ||
      !crmPipeline ||
      !pipelineFields[pipelineFieldIndex]?.values?.find(
        (item) => item.id === crmPipeline
      )
    ) {
      setSelectionError(true);
      return;
    }
    api.setSalesforcePipelineFields(
      publicationId,
      crmPipeline,
      { closedwon: closedWonStage, closedlost: closedLostStage },
      pipelineField,
      (res) => {
        if (res.status === 200) {
          handleClose();
        } else {
          notify.show(
            "Failed to save Details. Please try again later.",
            "error"
          );
        }
      }
    );
  };

  return (
    <>
      <StyledDialog
        open={open}
        title="Configure Salesforce CRM"
        body={
          <>
            {loading && <Skeleton width="100%" height={50} />}
            {!loading && errorDetails.error && (
              <Alert severity="error">{errorDetails.message}</Alert>
            )}

            {!loading && !errorDetails.error && (
              <>
                <Typography variant="bodym">
                  Select appropriate field and value for Opportunities that will
                  be used for attribution
                </Typography>
                <FormControl className={classes.formControl} fullWidth>
                  <Typography variant="bodym">Select a field</Typography>
                  <StyledSelect
                    onChange={(event: any) => {
                      setPipelineField(event.target.value);
                      setPipelineFieldIndex(
                        pipelineFields.findIndex(
                          (item) => item.id === event.target.value
                        )
                      );
                      setSelectionError(false);
                    }}
                    placeholder="Select Field"
                    value={pipelineField}
                    className={classes.formField}
                  >
                    {pipelineFields.map((item) => (
                      <StyledMenuItem key={item.id} value={item.id}>
                        {item.label}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                {pipelineFieldIndex >= 0 && (
                  <FormControl className={classes.formControl} fullWidth>
                    <Typography variant="bodym">Select a value</Typography>
                    <StyledSelect
                      onChange={(event: any) => {
                        setCrmPipeline(event.target.value);
                        setSelectionError(false);
                      }}
                      placeholder="Select Value"
                      value={crmPipeline}
                      className={classes.formField}
                    >
                      {pipelineFields[pipelineFieldIndex]?.values?.map(
                        (item) => (
                          <StyledMenuItem key={item.id} value={item.id}>
                            {item.label}
                          </StyledMenuItem>
                        )
                      )}
                    </StyledSelect>
                  </FormControl>
                )}
                <Divider className={classes.divider} />
                <FormControl className={classes.formControl} fullWidth>
                  <Typography variant="bodym">
                    Select a stage corresponds to "Closed/Won" Opportunities
                  </Typography>
                  <StyledSelect
                    onChange={(event: any) => {
                      setClosedWonStage(event.target.value);
                      setSelectionError(false);
                    }}
                    placeholder="Select Stage"
                    value={closedWonStage}
                    className={classes.formField}
                  >
                    {stages.map((item) => (
                      <StyledMenuItem key={item.id} value={item.id}>
                        {item.label}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                <FormControl className={classes.formControl} fullWidth>
                  <Typography variant="bodym">
                    Select a stage corresponds to "Closed/Lost" Opportunities
                  </Typography>
                  <StyledSelect
                    onChange={(event: any) => {
                      setClosedLostStage(event.target.value);
                      setSelectionError(false);
                    }}
                    placeholder="Select Stage"
                    value={closedLostStage}
                    className={classes.formField}
                  >
                    {stages.map((item) => (
                      <StyledMenuItem key={item.id} value={item.id}>
                        {item.label}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
                {selectionError && (
                  <Alert
                    severity="warning"
                    style={{
                      marginTop: 10
                    }}
                  >
                    Select all the fields
                  </Alert>
                )}
              </>
            )}
          </>
        }
        successButtonName="Save"
        successCallback={handleSave}
        cancelButtonName="Close"
        cancelCallback={handleClose}
      />
    </>
  );
}
