import colors from "../Themes/Colors";

export const planTypes = {
  FREE: "free",
  PAID: "paid"
};

export const linkedInReactionTypes = {
  LIKE: "like",
  LOVE: "love",
  CELEBRATE: "celebrate",
  SUPPORT: "support",
  INSIGHTFUL: "insightful",
  FUNNY: "funny"
};

export const linkedInReactions = {
  LIKE: "LIKE",
  PRAISE: "PRAISE",
  INTEREST: "INTEREST",
  APPRECIATION: "APPRECIATION",
  EMPATHY: "EMPATHY",
  ENTERTAINMENT: "ENTERTAINMENT"
};

export const CLEARBIT_LOGO_CDN = "https://logo.clearbit.com";

export const linkedInOptionTypes = {
  ACCOUNTS: "Accounts",
  ORGANIZATIONS: "Organizations",
  GROUPS: "Groups",
  ALL: "All"
};

export const roles = {
  WRITER: "writer",
  READER: "subscriber"
};

export const SendGridStatus = {
  PROCESSED: "processed",
  DEFERRED: "deferred",
  DELIVERED: "delivered",
  OPEN: "open",
  CLICK: "click",
  BOUNCE: "bounce",
  DROPPED: "dropped",
  SPAMREPORT: "spamreport",
  UNSUBSCRIBE: "unsubscribe"
};

export const linkedInPermissions = {
  LIKE: "like",
  COMMENT: "comment",
  POST: "post",
  EXTENSION: "extension"
};

export const frequencyTypes = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly"
};

export const calendarPeriods = {
  DAILY: "Day",
  WEEKLY: "Week",
  MONTHLY: "Month",
  YEARLY: "Year"
};

export const browseFilterTypes = {
  ALL: "all",
  NEW: "new"
};

export const domNodeName = {
  ANCHOR_TAG: "A"
};

export const postStatus = {
  PUBLISHED: "published",
  DRAFT: "draft",
  SCHEDULED: "scheduled",
  IN_REVIEW: "in_review",
  DELETED: "deleted"
};

export const campaignStatus = {
  SENT: "sent",
  DRAFT: "draft",
  SCHEDULED: "scheduled"
};

export const categories = {
  BUSINESS: "Business",
  FINANCE: "Finance",
  FOOD: "Food",
  HEALTHCARE: "Healthcare",
  LIFESTYLE: "Lifestyle",
  NEWS: "News",
  PERSONAL_DEVELOPMENT: "Personal Development",
  POLITICS: "Politics",
  SCIENCE: "Science",
  SPORTS: "Sports",
  TECHNOLOGY: "Technology",
  WRITING: "Writing"
};

export const postType = {
  PAID: "paid",
  FREE: "free"
};

export const subscriberType = {
  PAID: "paid",
  FREE: "free"
};

export const publicationType = {
  PAID: "paid",
  FREE: "free"
};

export const PROFILE_PIC = "profile_pic";

export const LIKE_POST = "like_post";

export const saveType = {
  AUTOSAVE: "autosave",
  REVIEW: "review",
  PUBLISH: "publish",
  TEMPLATE: "template"
};

export const subscriptionPlanTypes = {
  MONTHLY: "monthly",
  ANNUAL: "annual",
  FREE: "free"
};

export const imageTypes = [
  //"image/apng",
  //"image/bmp",
  "image/gif",
  "image/jpeg",
  //"image/pjpeg",
  "image/png",
  //"image/svg+xml",
  //"image/tiff",
  "image/webp"
  //"image/x-icon"
];

export const videoPrivacyStatusTypes = {
  PUBLIC: "public",
  UNLISTED: "unlisted",
  PRIVATE: "private"
};

export const timeFilters = {
  DATE_RANGE: 3,
  LAST_MONTH: 2,
  LAST_3_MONTHS: 1,
  ALL_TIME: 0
};

export const subscriberStatus = {
  ACTIVE: "active",
  CANCELLED: "cancelled"
};

export const CustomDomainStatus = {
  IN_PROGRESS: "inprogress",
  SUCCESS: "success",
  FAILED: "failed",
  NOT_CONNECTED: "notconnected"
};

export const subscriberEventType = {
  DELIVERED: "delivered",
  OPENED: "opened",
  CLICKED: "clicked",
  LIKED: "liked",
  FEEDBACK: "feedback",
  SUBSCRIBED: "subscribed",
  UNSUBSCRIBED: "unsubscribed",
  PAGEVIEW: "pageview",
  CLICK_TO_OPEN: "click_to_open",
  WEB_VISITS: "web_visits"
};

export const clientPlanTypes = {
  FREE: "free",
  PLAN1: "plan1",
  PLAN2: "plan2",
  PLAN3: "plan3"
};

export const PostSource = {
  TWITTER: "twitter", //https://t.co
  FACEBOOK: "facebook", //https://l.facebook.com/
  GOOGLE: "google",
  BLOGHOME: "bloghome", //https://letterdrop.co/@aztech // or custom domain match
  URL: "url", // referrer : ""
  EMAIL: "email",
  OTHER: "other"
};

export const formTypes = {
  TYPEFORM: "Typeform",
  GOOGLEFORM: "Google Form"
};

export const permissionTypes = {
  EDITOR: "editor",
  OWNER: "owner"
};

export const BLOGPOST_MODE = "blogpost_dark_mode";

export const TemplateType = {
  WELCOME: "welcome",
  THANKYOU: "thankyou",
  CUSTOM: "custom",
  CONTENTMAP: "content_map",
  TOPIC: "topic"
};

export const templateCategory = {
  MINE: "Mine",
  BLOG: "Blog",
  NEWSLETTER: "Newsletter"
};

export const subscriberSource = {
  SELF: "self",
  IMPORTED: "imported",
  API: "api"
};

export const subscriberSourceMedium = {
  HOME: "home",
  POST: "post",
  EMBED: "embed"
};

export const DEFAULT_BLOCKQUOTE_COLOR = "#0F79FE";
export const CUSTOM_BUTTON_BACKGROUND = "#0F79FE";

export const planTimePeriod = {
  MONTH: "month"
};

export const socialLinkType = {
  DISCORD: "Discord",
  FACEBOOK: "Facebook",
  GITHUB: "GitHub",
  SPOTIFY: "Spotify",
  INSTAGRAM: "Instagram",
  LINKEDIN: "LinkedIn",
  SLACK: "Slack",
  TWITTER: "Twitter",
  YOUTUBE: "YouTube",
  DONATE: "Donate",
  NOTION: "Notion"
};

export const teamRoles = {
  ADMIN: "admin",
  TEAMMATE: "teammate",
  WRITER: "writer",
  VIEWER: "viewer",
  FREELANCER: "freelancer",
  AGENCY: "agency"
};

export const planVariants = {
  PUBLICATION: "publication"
};

export const editorType = {
  QUILL_EDITOR: "quill_editor",
  UNLAYER_EDITOR: "unlayer_editor"
};

export const publicationReviewedStatus = {
  NOT_REVIEWED: "not_reviewed",
  IN_REVIEW: "in_review",
  APPROVED: "approved"
};

export const statsType = {
  POST: "post",
  CAMPAIGN: "campaign"
};

export const deleteCalenderOptions = {
  THIS_EVENT: 0,
  THIS_AND_FOLLOWING: 1,
  ALL_EVENTS: 2
};

export const optionsForLogo = {
  SQUARE: "square",
  RECTANGLE: "rectangle"
};

export const customFieldDataType = {
  NUMBER: "Number",
  TEXT: "Text",
  URL: "URL",
  BOOLEAN: "Boolean",
  DATE: "Date",
  MULTI_SELECT_LIST: "Multi-Select List",
  SINGLE_SELECT_LIST: "Single-Select List",
  RICH_TEXT: "Rich text (HTML)",
  IMAGE: "Image"
};

export const apiFetchStatus = {
  IDLE: "idle",
  FETCH: "fetching",
  SUCCESS: "success",
  FAIL: "failed"
};

export const blogHosting = {
  WEBFLOW: "webflow",
  WORDPRESS: "wordpress",
  SHOPIFY: "shopify",
  GHOST: "ghost",
  HUBSPOT: "hubspot",
  CONTENTFUL: "contentful",
  STRAPI: "strapi",
  OTHERS: "others"
};

export const enableCommentsType = {
  OFF: "off",
  ALL_POSTS: "all posts",
  ONLY_PAID_POSTS: "only paid posts"
};

export const topicStatus = {
  DRAFT: "draft",
  PROPOSED: "proposed",
  CONTENT_MAP_DRAFT: "content_map_draft",
  CONTENT_MAP_IN_REVIEW: "content_map_in_review",
  FIRST_DRAFT: "first_draft",
  IN_REVIEW: "in_review",
  SCHEDULED: "scheduled",
  PUBLISHED: "published",
  ARCHIVED: "archived",
  DELETED: "deleted",
  OVERDUE: "overdue"
};

export const ideaTemplateStatus = {
  DRAFT: "draft",
  ACTIVE: "active"
};

export const topicEvent = {
  SUGGESTED: "suggested",
  ASSIGNED: "assigned",
  ASSIGNED_TO: "assigned_to",
  APPROVER_CHANGE: "approver_change",
  CONTRIBUTOR_CHANGE: "contributor_change",
  CONTENT_MAP_DRAFT_SUBMITTED: "content_map_draft_submitted",
  CONTENT_MAP_DRAFT_CHANGE_REQUEST: "content_map_draft_change_request",
  CONTENT_MAP_DRAFT_APPROVED: "content_map_draft_approved",
  CONTENT_MAP_DEADLINE_MODIFIED: "content_map_deadline_modified",
  POST_DRAFT_SUBMITTED: "post_draft_submitted",
  POST_DRAFT_CHANGE_REQUEST: "post_draft_change_request",
  POST_DRAFT_APPROVED: "post_draft_approved",
  POST_DRAFT_DEADLINE_MODIFIED: "post_draft_deadline_modified",
  PUBLISH_DATE_MODIFIED: "publish_date_modified",
  PUBLISHED: "published",
  SCHEDULED: "scheduled",
  ARCHIVED: "archived",
  UNARCHIVED: "unarchived",
  UPDATED: "updated",
  STAGE_OWNER_MODIFIED: "stage_owner_modified",
  STAGE_DEADLINE_MODIFIED: "stage_date_modified",
  MOVE_BACK_TO_IDEAS: "move_back_to_ideas"
};

export const sortDirection = {
  ASCENDING: "asc",
  DESCENDING: "desc"
};

export const unsubscribeCause = {
  BY_YOU: "by_you",
  FROM_EMAIL: "from_email",
  BOUNCED: "bounced",
  API: "api",
  MANUAL: "manual"
};

export const DEFAULT_IMAGE_CAPTION = "Image caption";

export const selectedPostFile = {
  SOCIAL_THUMBNAIL: "social_thumbnail",
  POST_COVER: "post_cover",
  EMAIL_BANNER: "email_banner",
  CUSTOM_IMAGE: "custom_image"
};

export const emailBannerType = {
  NO_EMAIL_BANNER: "No email banner",
  UPLOAD_EMAIL_BANNER: "Upload an email banner",
  CROPPED_COVER_PHOTO: "Use a cropped version of cover photo"
};

export const postVariant = {
  BLOG_POST: "Blog Post",
  EMAIL_NEWSLETTER: "Email Newsletter",
  BOTH: "Both",
  NO_POST: "No Post"
};

export const postPublishType = {
  NOW: "now",
  LATER: "later"
};

export const socialMediaPublishTypes = {
  NOW: "now",
  LATER: "later",
  WITH_POST: "with_post",
  TEN_MINUTES_LATER: "ten_minutes_later",
  THIRTY_MINUTES_LATER: "thirty_minutes_later",
  HOUR_LATER: "hour_later",
  DAY_LATER: "day_later"
};

export const htmlTag = {
  SCRIPT: "script",
  META: "meta"
};

export const statsCategory = {
  LINKS_CLICKED: "linksClicked",
  RECIPIENTS: "recipients"
};

export const subscriberExportStatus = {
  STARTED: "started",
  IN_PROGRESS: "inprogress",
  COMPLETED: "completed"
};

export const recipientExportStatus = {
  STARTED: "started",
  IN_PROGRESS: "inprogress",
  COMPLETED: "completed"
};

export const pageAccess = {
  POSTS: "posts",
  SETTINGS: "settings",
  EDIT_SETTINGS: "edit_settings",
  SUBSCRIBERS: "subscribers",
  PUBLICATION_STATS: "publication_stats",
  POST_STATS: "post_stats",
  CALENDER: "calender",
  OVERVIEW: "overview",
  TEMPLATES: "templates",
  LISTS: "lists",
  PUBLISH_POST: "publish_post",
  CREATE_POST: "create_post",
  EDIT_POST: "edit_post",
  FEATURE_POST: "feature_post",
  DELETE_POST: "delete_post",
  DUPLICATE_POST: "duplicate_post",
  SUBMIT_FOR_POST_APPROVAl: "submit_for_post_approval",
  EDIT_SUBSCRIBERS: "edit_subscriber",
  EDIT_CALENDAR_EVENT: "edit_calendar_event",
  ASSIGN_TOPIC: "assign_topic",
  EDIT_TOPIC: "edit_topic",
  CONNECT_TWITTER: "connect_twitter",
  CONVERSATION: "conversation",
  CAMPAIGNS: "campaigns",
  SUGGEST_RECYCLE: "recycle",
  ADD_CALENDAR_EVENT: "add_calendar_event"
};

export const tableToQuery = {
  POSTS: "posts",
  POST_STATS: "poststats"
};

export const dimensionToQuery = {
  PAGE: "Page",
  QUERY: "Query",
  COUNTRY: "Country"
};

export const uploadFileTypes = {
  IMAGE: "image",
  VIDEO: "video",
  PDF: "pdf",
  CSV: "csv",
  EXCEL: "excel"
};

export const defaultImageUrl = {
  DOCS: "/resources/images/default_doc.svg",
  VIDEO: "/resources/images/default_video.svg",
  PDF: "/resources/images/default_pdf.svg",
  EXCEL: "/resources/images/default_excel.svg"
};

export const acceptedFileUploadTypes =
  ".gif, .png, .jpg, .svg, .webp, .tiff, .eps, .raw, .bmp, .txt, .pdf, .mp4, .mov, .avi, .wmv, .flv, .mp3, .aac, .wav, .csv, .docx, .xls, .pptx";

export const socialMediaType = {
  LINKED_IN: "linkedin",
  TWITTER: "twitter",
  WEBFLOW: "webflow",
  SLACK: "slack",
  WORDPRESS: "wordpress",
  MEDIUM: "medium",
  DEVTO: "devto",
  HASHNODE: "hashnode",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  HUBSPOT: "hubspot",
  GOOGLE_SEARCH_CONSOLE: "gsc",
  GOOGLE_ANALYTICS: "ga",
  SHOPIFY: "shopify",
  GHOST: "ghost",
  SHARE_AS_IMAGE: "share_as_image",
  REDDIT: "reddit",
  SALESFORCE: "salesforce",
  YOUTUBE: "youtube",
  CLEARBIT: "clearbit",
  APOLLO: "apollo"
};

export const TWEETS_MAX_LENGTH = 28000;
export const THREAD_MAX_LENGTH = 280;
export const TWEETS_LINK_LENGTH = 23;

export const LINKEDIN_CHAR_LIMIT = 3000;
export const LINKEDIN_COMMENT_CHAR_LIMIT = 1250;
export const FACEBOOK_CHAR_LIMIT = 60000;
export const REDDIT_BODY_CHAR_LIMIT = 40000;
export const REDDIT_TITLE_CHAR_LIMIT = 300;
export const INSTAGRAM_CAPTION_LIMIT = 2200;

export const graphStatsType = {
  PAGEVIEWS: "pageviews",
  EMAIL_OPENS: "email_opens"
};

export const paletteColors = [
  colors.primary,
  colors.whiteTheme,
  colors.redTheme,
  colors.blueTheme,
  colors.greenTheme,
  colors.yellowTheme,
  colors.orangeTheme,
  colors.purpleTheme,
  colors.black
];

export const defaultFontsList = [
  { fontFamily: "Arial" },
  { fontFamily: "Georgia" },
  { fontFamily: "Helvetica" },
  { fontFamily: "Times New Roman" },
  { fontFamily: "Verdana" }
];

export const headerCustomizationColor = {
  FONT_COLOR: "font_color",
  BACKGROUND_COLOR: "background_color",
  GRADIENT: "gradient"
};

export const DEFAULT_GRADIENT_BACKGROUND = [
  { offset: "0.00", color: colors.primary },
  { offset: "1.00", color: colors.white }
];

export const DEFAULT_GRADIENT_DEGREE = 90;

export const backgroundStyleOptions = {
  SOLID: "Solid",
  TRANSPARENT: "Transparent",
  GRADIENT: "Gradient",
  FADE: "Fade"
};

export const alignType = {
  CENTER: "center",
  LEFT: "left"
};

export const CommentPanelMode = {
  NEW_COMMENT: "NEW_COMMENT",
  SHOW_COMMENT: "SHOW_COMMENT",
  ALL_COMMENT: "ALL_COMMENT"
};

export const contentAnalyzerPanel = {
  SEO: 0,
  COMPARE: 1,
  ORIGINALITY: 3
};

export const copyleaksStatus = {
  STARTING: "starting",
  RUNNING: "running",
  COMPLETED: "completed",
  ERROR: "error",
  CREDITS_CHECKED: "creditsChecked"
};

export const contentScoreTone = {
  TEXT: "tone",
  FORMAL: "Formal",
  INFORMAL: "Informal"
};

export const contentScoreSentiment = {
  TEXT: "sentiment",
  POSITIVE: "Positive",
  NEGATIVE: "Negative"
};

export const contentScorePersonalism = {
  TEXT: "personalism",
  PERSONAL: "Personal",
  IMPERSONAL: "Impersonal"
};

export const AppHeaders = {
  OVERVIEW: "overview",
  POSTS: "posts",
  TOPICBOARD: "topics",
  PUBLICATION_STAT: "publicationstat",
  SUBSCRIBERS: "subscribers",
  CALENDAR: "calendar",
  PROJECTS: "projects",
  LISTS: "lists",
  SETTINGS: "settings",
  TEMPLATES: "templates",
  KEYWORD_RESEARCH: "keywordresearch",
  SEARCH_SUBSCRIBER: "searchsubscriber",
  LIST: "list",
  POSTSTAT: "poststat",
  CAMPAIGNS: "campaigns",
  CAMPAIGN_STATS: "campaignstats",
  REDDIT: "reddit",
  LINKEDIN: "linkedin",
  TWITTER: "twitter",
  SLACK: "slack",
  INSTAGRAM: "instagram"
};

export const contentMapFieldTypes = {
  INSTRUCTION: "instruction",
  INPUT: "input"
};

export const KeywordDataSource = {
  DATAFORSEO: "dataforseo",
  SEMRUSH: "semrush"
};

export const keywordResearchType = {
  KEYWORD: "keyword",
  DOMAIN: "domain"
};

//Match all url starting with or without (https/http/www)
export const URL_REGEX =
  /(?<!="|<[^>]*)(?<!(data-value="[^"]*))(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/g;

export const webflowCustomFieldTypes = {
  DATE: "DateTime",
  PLAIN_TEXT: "PlainText",
  NUMBER: "Number",
  SWITCH: "Switch",
  MULTI_REFERENCE: "MultiReference",
  REFERENCE: "Reference",
  OPTION: "Option",
  LINK: "Link",
  RICH_TEXT: "RichText",
  MULTI_IMAGE: "MultiImage",
  IMAGE: "Image"
};

export const postFields = {
  thumbnail: {
    name: "Thumbnail",
    slug: "thumbnail",
    type: webflowCustomFieldTypes.IMAGE
  },
  metaDescription: {
    name: "Meta Description",
    slug: "meta_description",
    type: webflowCustomFieldTypes.PLAIN_TEXT
  },
  metaTitle: {
    name: "Meta Title",
    slug: "meta_title",
    type: webflowCustomFieldTypes.PLAIN_TEXT
  },
  title: {
    name: "Title",
    slug: "title",
    type: webflowCustomFieldTypes.PLAIN_TEXT
  },
  subtitle: {
    name: "Subtitle",
    slug: "subtitle",
    type: webflowCustomFieldTypes.PLAIN_TEXT
  },
  bodyHtml: {
    name: "Body (HTML)",
    slug: "body_html",
    type: webflowCustomFieldTypes.RICH_TEXT
  },
  textPreview: {
    name: "Text Preview",
    slug: "text_preview",
    type: webflowCustomFieldTypes.PLAIN_TEXT
  },
  authorName: {
    name: "Author Name",
    slug: "author_name",
    type: webflowCustomFieldTypes.PLAIN_TEXT
  },
  authorNameSingle: {
    name: "Author Name",
    slug: "author_name_single",
    type: webflowCustomFieldTypes.REFERENCE
  },
  authorNameMultiple: {
    name: "Author Name",
    slug: "author_name_multiple",
    type: webflowCustomFieldTypes.MULTI_REFERENCE
  },
  authorProfilePicture: {
    name: "Author Profile Picture",
    slug: "author_profile_picture",
    type: webflowCustomFieldTypes.IMAGE
  },
  featured: {
    name: "Featured",
    slug: "featured",
    type: webflowCustomFieldTypes.SWITCH
  },
  bodyPlainText: {
    name: "Body (Plain Text)",
    slug: "body_plain_text",
    type: webflowCustomFieldTypes.PLAIN_TEXT
  },
  readTime: {
    name: "Read Time",
    slug: "read_time",
    type: webflowCustomFieldTypes.NUMBER
  },
  readTimeString: {
    name: "Read Time",
    slug: "read_time_string",
    type: webflowCustomFieldTypes.PLAIN_TEXT
  },
  coverImage: {
    name: "Cover Image",
    slug: "cover_image",
    type: webflowCustomFieldTypes.IMAGE
  },
  publishedOn: {
    name: "Published On",
    slug: "published_on",
    type: webflowCustomFieldTypes.DATE
  },
  publishedOnString: {
    name: "Published On",
    slug: "published_on_string",
    type: webflowCustomFieldTypes.PLAIN_TEXT
  },
  tag: {
    name: "Tag",
    slug: "tag",
    type: webflowCustomFieldTypes.REFERENCE
  },
  tags: {
    name: "Tags",
    slug: "tags",
    type: webflowCustomFieldTypes.MULTI_REFERENCE
  }
};

export const socialMediaStatus = {
  IN_PROGRESS: "in_progress",
  DRAFT: "draft",
  IN_REVIEW: "in_review",
  SCHEDULED: "scheduled",
  COMPLETED: "completed"
};

export const redditPostStatus = {
  IN_PROGRESS: "in_progress",
  DRAFT: "draft",
  SCHEDULED: "scheduled",
  COMPLETED: "completed"
};

export const mediaPublishTypes = {
  NOW: "now",
  LATER: "later",
  WITH_POST: "with_post"
};

export const tweetStatus = {
  IN_PROGRESS: "in_progress",
  DRAFT: "draft",
  IN_REVIEW: "in_review",
  SCHEDULED: "scheduled",
  COMPLETED: "completed"
};

export const facebookPostStatus = {
  IN_PROGRESS: "in_progress",
  DRAFT: "draft",
  SCHEDULED: "scheduled",
  COMPLETED: "completed"
};

export const tweetPublishType = {
  NOW: "now",
  LATER: "later",
  WITH_POST: "with_post",
  TEN_MINUTES_LATER: "ten_minutes_later",
  THIRTY_MINUTES_LATER: "thirty_minutes_later",
  HOUR_LATER: "hour_later",
  DAY_LATER: "day_later"
};

export const facebookPublishTypes = {
  NOW: "now",
  LATER: "later",
  WITH_POST: "with_post",
  TEN_MINUTES_LATER: "ten_minutes_later",
  THIRTY_MINUTES_LATER: "thirty_minutes_later",
  HOUR_LATER: "hour_later",
  DAY_LATER: "day_later"
};

export const redditPublishType = {
  NOW: "now",
  LATER: "later",
  WITH_POST: "with_post",
  TEN_MINUTES_LATER: "ten_minutes_later",
  THIRTY_MINUTES_LATER: "thirty_minutes_later",
  HOUR_LATER: "hour_later",
  DAY_LATER: "day_later"
};

export const backlinkFilter = {
  CURRENT: "current",
  NEW: "new",
  LOST: "lost"
};

export const calendarEventType = {
  SCHEDULED_POST: "scheduled_post",
  PUBLISHED_POST: "published_post",
  SCHEDULED_CAMPAIGN: "scheduled_campaign",
  PUBLISHED_CAMPAIGN: "published_campaign",
  SOCIAL_MEDIA: "social_media",
  PLACEHOLDER_EVENT: "placeholder_event",
  TOPIC_BOARD: "topic_board"
};

export const editorSidePanelType = {
  AIPANEL: "ai_panel",
  COMMENTSPANEL: "comments_panel",
  CONTENT_ANALYZER: "content_analyzer",
  SIMPLIFY_PANEL: "simplify_panel",
  HISTORY_PANEL: "history_panel",
  CHAT_PANEL: "chat_panel",
  REFERENCE_PANEL: "reference_panel",
  CONTENT_OUTLINE: "content_outline",
  CONTENT_BRIEF: "content_brief"
};

export const contentType = {
  STRING: "string",
  HTML: "html"
};

export const sequenceState = {
  ACTIVE: "active",
  PAUSED: "paused",
  DRAFT: "draft",
  ARCHIVED: "archived",
  DELETED: "deleted"
};

export const editorTopBarType = {
  POST: "post",
  PREAMBLE_AND_FOOTER: "preamble_and_footer",
  POST_REVIEW: "post_review",
  CAMPAIGN: "campaign",
  BLOG_GENERATOR: "blog_generator"
};

export const aiPanel = {
  SELECTION: "selection",
  TITLE_INPUT: "title_input",
  BLOGPOST_INPUT: "blogpost_input",
  SELECT_HEADLINE: "select_headline",
  SECTION_HEADER: "section_header",
  SECTION_OUTLINE: "section_outline",
  OUTLINE_PARAGRAPH: "outline_paragraph",
  SECTION_INTRODUCTION: "section_introduction",
  SECTION_CONCLUSION: "section_conclusion"
};

export const engagementType = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
  NONE: "None"
};
export const DEFAULT_EDITOR_FONT = "Charter, Georgia";
export const DEFAULT_BLOG_FONT = "Inter, sans-serif";

export const postRightControlButtons = {
  STYLE_GUIDE: "style_guide",
  CONTENT_BRIEF: "content_brief",
  CONTENT_MAP: "content_map",
  SEO_OPTIMIZATION: "seo_optimization",
  SERP_RESEARCH: "serp_research",
  COMMENTS: "comments",
  SHARE: "share",
  HISTORY: "history",
  CHAT: "chat",
  MORE_MENU: "more_menu",
  TOPIC: "topic"
};

export const blogGeneratorSteps = {
  keyword: { type: "keyword", name: "Keyword", value: 25 },
  title: { type: "title", name: "Title", value: 50 },
  section_headers: {
    type: "section_headers",
    name: "Section Headers",
    value: 75
  },
  section_outline: {
    type: "section_outline",
    name: "Section Outline",
    value: 100
  }
};

export const subscriberMergeTags = [
  { name: "Email", field: "email", type: "string" },
  { name: "Signed Up On", field: "signedUpOn", type: "date" },
  { name: "Emails Opened", field: "emailsOpened", type: "number" },
  { name: "Emails Sent", field: "emailsSent", type: "number" },
  { name: "Links Clicked", field: "linksClicked", type: "number" },
  { name: "Engagement", field: "engagement", type: "string" },
  { name: "Subscription Type", field: "type", type: "string" },
  { name: "Status", field: "status", type: "string" },
  { name: "Subscription End Date", field: "endDate", type: "date" },
  { name: "Referred", field: "referred", type: "number" },
  { name: "Referrer", field: "referrer", type: "ref" },
  { name: "Source", field: "source", type: "string" }
];

export const mediaFileType = {
  IMAGE: "image",
  GIF: "gif",
  VIDEO: "video",
  PDF: "pdf",
  DOC: "doc",
  DOCX: "docx",
  PPT: "ppt",
  PPTX: "pptx"
};

export const blogGeneratorField = {
  TITLE: "title",
  SUBTITLE: "subtitle"
};

export const appNotification = {
  TOPIC_ASSIGNED: "topic_assigned",
  DRAFT_REVIEW_REQUEST: "draft_review_request",
  CONTENT_MAP_REVIEW_REQUEST: "content_map_review_request",
  CONTENT_MAP_APPROVED: "content_map_approved",
  DRAFT_APPROVED: "draft_approved",
  COMMENT_ON_TOPIC: "comment_on_topic",
  COMMENT_ON_IDEA: "comment_on_idea",
  POST_PUBLISHED: "post_published",
  POST_UPDATED: "post_updated",
  POST_PREVIEWED: "post_previewed",
  POST_PREVIEW_DELETED: "post_preview_deleted",
  POST_DELETED: "post_deleted",
  POST_UNPUBLISHED: "post_unpublished",
  POST_NOT_PUBLISHED_WEBFLOW: "post_not_published_webflow",
  POST_UPDATED_WEBHOOK_EVENT: "post_updated_webhook_event",
  SOCIAL_MEDIA_PUBLISHED: "social_media_published",
  SOCIAL_MEDIA_APPROVAL: "social_media_approval",
  CONTENT_DUE: "content_due",
  CONTENT_OVER_DUE: "content_over_due",
  STATUS_CHANGED: "status_changed",
  LINK_SUGGESTIONS: "link_suggestions",
  SUMMARY_GENERATED: "summary_generated",
  SOCIAL_POST_GENERATED: "social_post_generated",
  DRAFT_POST_FROM_THEME_GENERATED: "draft_post_from_theme_generated",
  AI_GENERATION: "ai_generation",
  VIDEO_UPLOADED_TO_YOUTUBE: "video_uploaded_to_youtube",
  VIDEO_READY_FOR_DOWNLOAD: "video_ready_for_download",
  ERROR_NOTIFICATION: "error_notification"
};

export const postAuditLogsType = {
  POST_PUBLISHED: "post_published",
  POST_UPDATED: "post_updated",
  POST_UPDATED_WEBHOOK_EVENT: "post_updated_webhook_event",
  POST_PREVIEWED: "post_previewed",
  POST_PREVIEW_DELETED: "post_preview_deleted",
  POST_DELETED: "post_deleted",
  POST_UNPUBLISHED: "post_unpublished"
};

export const postAuditLogsLabel = {
  [postAuditLogsType.POST_PUBLISHED]: "Post was published",
  [postAuditLogsType.POST_UPDATED]: "Post was updated",
  [postAuditLogsType.POST_UPDATED_WEBHOOK_EVENT]:
    "Post metadata was updated to match Webflow",
  [postAuditLogsType.POST_PREVIEWED]: "Post was previewed",
  [postAuditLogsType.POST_PREVIEW_DELETED]: "Post preview was deleted",
  [postAuditLogsType.POST_DELETED]: "Post was deleted",
  [postAuditLogsType.POST_UNPUBLISHED]: "Post was unpublished"
};

export const blogDestinations = {
  GHOST: "ghost",
  HUBSPOT: "hubspot",
  SHOPIFY: "shopify",
  WEBFLOW: "webflow",
  WORDPRESS: "wordpress",
  WEBHOOK: "webhook"
};

export const postBulkActionTypes = {
  UPDATE_TITLE: "update_title",
  UPDATE_TAGS: "update_tags",
  UPDATE_DESCRIPTION: "update_description",
  UPDATE_URL: "update_url",
  UNPUBLISH: "unpublish",
  DELETE: "delete"
};

export const ENCODED_URL_PREFIX = "https%3A%2F%2";

export const customFieldsTypes = {
  BLOG_CUSTOM_FIELDS: "blog_custom_fields",
  SUBSCRIBER_CUSTOM_FIELDS: "subscriber_custom_fields"
};

export const InputFieldTypes = {
  NUMBER: "number"
};

export const CUSTOM_FIELD = "_custom_field";

export const contentStageSectionNames = {
  OUTLINING: "Outlining",
  DRAFTING: "Drafting",
  COMPLETED: "Completed"
};

export const TITLE_LENGTH = 120;

export const assigneeSelectionTypes = {
  LET_USER_CHOOSE: "let_user_choose",
  SAME_AS_STAGE: "same_as_stage",
  PROJECT_OWNER: "project_owner"
};

export const contentDueRemainder = {
  CONTENT_DUE: "content_due",
  CONTENT_OVER_DUE: "content_over_due"
};

export const linkedinAccountLocationType = {
  LOCATION: "location",
  COMPANY_LOCATION: "companyLocation"
};

export const ASSIGNEE_NOT_REQUIRED_STAGES = [
  topicStatus.FIRST_DRAFT,
  topicStatus.SCHEDULED,
  topicStatus.PUBLISHED
];

export const editorSuggestionTypes = {
  ADDED_TEXT_SUGGESTION: "addedtextsuggestion",
  REMOVED_TEXT_SUGGESTION: "removedtextsuggestion"
};

export const datePickerDefault = {
  SOCIAL: "social",
  DEADLINE: "deadline"
};

export const webflowImportType = {
  WEBFLOW_TO_LETTERDROP: "webflow_to_letterdrop",
  LETTERDROP_TO_WEBFLOW: "letterdrop_to_webflow",
  DO_NOT_IMPORT: "do_not_import"
};

export const commentStatus = {
  PUBLISHED: "published",
  DRAFT: "draft",
  DELETED: "deleted"
};

export const projectOwnerType = {
  MINE: "mine",
  ALL: "all"
};

export const searchType = {
  LINK_FROM: "link_from",
  LINK_TO: "link_to"
};

export const customFieldTypes = {
  [customFieldDataType.DATE]: [webflowCustomFieldTypes.DATE],
  [customFieldDataType.TEXT]: [webflowCustomFieldTypes.PLAIN_TEXT],
  [customFieldDataType.NUMBER]: [webflowCustomFieldTypes.NUMBER],
  [customFieldDataType.BOOLEAN]: [webflowCustomFieldTypes.SWITCH],
  [customFieldDataType.MULTI_SELECT_LIST]: [
    webflowCustomFieldTypes.MULTI_REFERENCE
  ],
  [customFieldDataType.SINGLE_SELECT_LIST]: [
    webflowCustomFieldTypes.REFERENCE,
    webflowCustomFieldTypes.OPTION
  ],
  [customFieldDataType.URL]: [webflowCustomFieldTypes.LINK],
  [customFieldDataType.RICH_TEXT]: [webflowCustomFieldTypes.RICH_TEXT],
  [customFieldDataType.IMAGE]: [
    webflowCustomFieldTypes.IMAGE,
    webflowCustomFieldTypes.MULTI_IMAGE
  ]
};

export const customFieldTypesForWebflowTypes = {
  [webflowCustomFieldTypes.DATE]: customFieldDataType.DATE,
  [webflowCustomFieldTypes.PLAIN_TEXT]: customFieldDataType.TEXT,
  [webflowCustomFieldTypes.NUMBER]: customFieldDataType.NUMBER,
  [webflowCustomFieldTypes.SWITCH]: customFieldDataType.BOOLEAN,
  [webflowCustomFieldTypes.MULTI_REFERENCE]:
    customFieldDataType.MULTI_SELECT_LIST,
  [webflowCustomFieldTypes.REFERENCE]: customFieldDataType.SINGLE_SELECT_LIST,
  [webflowCustomFieldTypes.OPTION]: customFieldDataType.SINGLE_SELECT_LIST,
  [webflowCustomFieldTypes.LINK]: customFieldDataType.URL,
  [webflowCustomFieldTypes.RICH_TEXT]: customFieldDataType.RICH_TEXT,
  [webflowCustomFieldTypes.MULTI_IMAGE]: customFieldDataType.IMAGE,
  [webflowCustomFieldTypes.IMAGE]: customFieldDataType.IMAGE
};

export const SAMPLE_SNIPPET = `<!-- TradingView Widget BEGIN -->
<div class="tradingview-widget-container">
  <div class="tradingview-widget-container__widget"></div>
  <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/symbols/EURUSD/?exchange=FX" rel="noopener" target="_blank"><span class="blue-text">EURUSD Rates</span></a> by TradingView</div>
  <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js" async>
  {
  "symbol": "FX:EURUSD",
  "width": 350,
  "height": 220,
  "locale": "en",
  "dateRange": "12M",
  "colorTheme": "light",
  "trendLineColor": "#37a6ef",
  "underLineColor": "#E3F2FD",
  "isTransparent": false,
  "autosize": false,
  "largeChartUrl": ""
}
  </script>
</div>
<!-- TradingView Widget END -->`;

export const socialNetwork = {
  TWITTER: { PATH: "/:id/twitter/drafts", NAME: "Twitter" },
  LINKEDIN: { PATH: "/:id/linkedin/drafts", NAME: "LinkedIn" },
  FACEBOOK: { PATH: "/:id/facebook/drafts", NAME: "Facebook" },
  INSTAGRAM: { PATH: "/:id/instagram/drafts", NAME: "Instagram" },
  SLACK: { PATH: "/:id/slack/drafts", NAME: "Slack" },
  REDDIT: { PATH: "/:id/reddit/drafts", NAME: "Reddit" }
};

export const contentRefreshTypes = {
  DECAYING_PAGES: "Decaying Pages",
  ALMOST_RANKING: "Almost Ranking",
  SPLIT_PAGES: "Split Pages",
  KEYWORD_CANNIBALIZATION: "Keyword Cannibalization",
  IMPROVE_DISTRIBUTION: "Improve Distribution",
  PAA_OPPORTUNITY: "PAA Opportunity"
};

export const contentRefreshPageSuggestionStatus = {
  RESOLVED: "resolved",
  IGNORE: "ignore"
};

export const channelTypes = {
  BLOG: "Blog",
  EMAIL: "Email",
  TWITTER: "Twitter",
  LINKEDIN: "LinkedIn",
  FACEBOOK: "Facebook",
  SLACK: "Slack",
  REDDIT: "Reddit",
  SOCIAL: "Social"
};

export const seoImprovements = {
  KEYWORD_IN_INTRODUCTION: "keyword_in_introduction",
  KEYWORD_IN_CONCLUSION: "keyword_in_conclusion",
  TLDR: "tldr",
  KEYWORD_IN_HEADINGS: "keyword_in_headings"
};
export const WEBFLOW_PREVIEW_TIMEOUT_IN_MINUTES = 3;

export const generateSocialPostStatus = {
  PENDING: "pending",
  COMPLETED: "completed"
};

export const postImprovementTypes = {
  FROM_POST: "from_post",
  FROM_URL: "from_url"
};

export const errorCodes = {
  OUTLINE_NOT_APPROVED: "outline_not_approved"
};

export const topQuestionsStatus = {
  PENDING: "pending",
  COMPLETED: "completed"
};

export const informationGainStatus = {
  PENDING: "pending",
  COMPLETED: "completed"
};

export const eeatQualityRatingStatus = {
  PENDING: "pending",
  COMPLETED: "completed"
};

export const webhookAIStatus = {
  PENDING: "pending",
  COMPLETED: "completed"
};

export const podcastTypes = {
  YOUTUBE: "youtube",
  LOOM: "loom",
  MP4: "mp4",
  MP3: "mp3",
  BLOG_POST: "blog_post",
  TEXT: "text"
} as const;

export type PodcastType = (typeof podcastTypes)[keyof typeof podcastTypes];

export const summaryTemplateTypes = {
  PODCAST: "podcast",
  TEXT: "text"
};

// Used same status in python server, if you modify this, please update in python server as well
export const podcastSummaryStatus = {
  PENDING: "pending",
  COMPLETED: "completed",
  FAILED: "failed"
};

export const questionType = {
  ANSWERED: "answered",
  UNANSWERED: "unanswered",
  FAQ: "faq"
};

export const internalLinkSource = {
  GSC_KEYWORD: "gsc_keyword",
  TARGET_KEYWORD: "target_keyword",
  TITLE: "title",
  AI_GENERATED_KEYWORD: "ai_generated_keyword"
};

export const unhealthyLinkType = {
  BROKEN: "broken",
  DUPLICATE: "duplicate",
  REDIRECTED: "redirect",
  ACTIVE: "active"
};

export const siteAnalyticsTypes = {
  CLICKS: "clicks",
  IMPRESSIONS: "impressions",
  VIEWS: "views"
};

export const linkedInAnalyticsTypes = {
  POSTS: "posts",
  LIKES: "likes",
  COMMENTS: "comments",
  IMPRESSIONS: "impressions",
  CLICKS: "clicks",
  ENGAGEMENT: "engagement"
};

export const infoGainTypes = {
  DEFAULT: "Missing Topic",
  SURVEY: "Survey",
  CHALLENGE_STATUS_QUO: "Challenge Status Quo",
  NEW_PERSPECTIVE: "New Perspective",
  VERTICAL: "Vertical",
  NEXT_STEPS: "Next Steps"
};

export const analysisStatus = {
  PENDING: "pending",
  COMPLETED: "completed"
};

export const contentRefreshPAAStatus = {
  NOT_ANSWERED: "not_answered",
  ANSWERED_NOT_RANKING: "answered_but_not_ranking",
  RANKING: "answered_and_ranking"
};

export enum onboardingSteps {
  BUSINESS = "business",
  CLIENT_PREFERENCES = "client_preferences",
  CLIENT_INFO = "client_info",
  TRIAL_INFO = "trial_info",
  CARD_INFO = "card_info",
  CONNECT_LINKEDIN = "connect_linkedin",
  GENERATE_LINKEDIN_POST = "generate_linkedin_post",
  GENERATE_LINKEDIN_POST_FROM_CALL = "generate_linkedin_post_from_call",
  GENERATE_LINKEDIN_POST_FETCH_CALLS = "generate_linkedin_post_fetch_calls",
  GENERATE_LINKEDIN_POST_WITHOUT_CALL = "generate_linkedin_post_without_call",
  GENERATE_LINKEDIN_POST_FROM_TRANSCRIPT = "generate_linkedin_post_from_transcript",
  GENERATE_LINKEDIN_POST_FROM_BLOG = "generate_linkedin_post_from_blog",
  SEO_OPTIMIZATION = "seo_optimization"
}

export const getStartedStatus = {
  COMPLETE: "complete",
  INCOMPLETE: "incomplete"
};

export const LAST_MODIFIED_BY_LETTERDROP = "Letterdrop to match Webflow";

export const clientPreferenceOptions = {
  SEO: "SEO optimize content",
  REPURPOSE: "Repurpose webinars and podcasts",
  STREAMLINE: "Streamline operations with a content calendar",
  SHARE: "Get my team to share content on LinkedIn",
  MARKETING_INSIGHTS: "Gather marketing insights from sales calls",
  AI: "AI generate content",
  ONBOARDING_WITH_SUPPORT: "I'm onboarding with Customer Success"
};

export const clientPreferences = [
  {
    type: clientPreferenceOptions.SHARE,
    title: "Social Selling on LinkedIn",
    description:
      "For sales and marketing teams that want to build trust on LinkedIn to book more meetings"
  },
  {
    type: clientPreferenceOptions.SEO,
    title: "SEO optimize existing content",
    description:
      "For marketing teams that want to fill in competitive gaps and add unique insights to rank their content on Google"
  },

  // {
  //   type: clientPreferenceOptions.AI,
  //   title: "Create SEO first draft with AI",
  //   description:
  //     "For teams that have no content and want to shorten time to first draft"
  // },

  {
    type: clientPreferenceOptions.ONBOARDING_WITH_SUPPORT,
    title: "I'm onboarding with Customer Success",
    description:
      "Select this if you have a scheduled onboarding with support from our team"
  }
];

export const URL_REPLACE_TEXT = "{{link}}";

export const compositeSEOTypes = {
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low"
};

export const jobStatus = {
  DRAFT: "draft",
  SCHEDULED: "scheduled",
  COMPLETED: "completed",
  FAILED: "failed"
};

export const outlineTypes = {
  HEADINGS_AND_POINTS: "bullet points",
  COMPLETE_ARTICLE: "paragraphs"
};

export const keywordType = {
  PRIMARY_KEYWORD: "primary_keyword",
  SECONDARY_KEYWORD: "secondary_keyword"
};

export enum SortOrderEnum {
  ASC = "asc",
  DESC = "desc"
}

export type marketingCampaign = {
  name: string;
  _id: string;
  publication: string;
  _v: number;
};

export type filterValue = {
  userId: string;
  name: string;
};

export type InternalLink = {
  keyword: string;
  url: string;
  score: number;
  referenceKeyword: string;
  linkSource: string;
};

export const subjectQuoteProcessStatus = {
  PENDING: "pending",
  GETTING_TRANSCRIPT: "getting_transcript",
  TRANSCRIPT_GENERATEED: "transcript_generated",
  GENERATING_VIDEO: "generating_video",
  GENERATED_VIDEO: "generated_video",
  FAILED: "failed",
  COMPLETED: "completed"
};

export type SeoScore = {
  conversionLikelihoodScore: number;
  finalRating: "low" | "medium" | "high";
  finalScore: number;
  funnelStage: string;
  informationCoverageScore: number;
  internalLinksCount: number;
  keyword: string;
  keywordCoverageScore: number;
  post: string;
  publication: string;
  technicalSEOScore: number;
};

export const linkedInActivityTypes = {
  SHARE: "share",
  COMMENT: "comment",
  LIKE: "like"
};

export const LINKEDIN_ACC_TYPE = {
  ORG: "org",
  PERSON: "person"
};

export const programmaticPostSources = {
  GSC: "gsc",
  GONG: "gong",
  UPLOAD: "upload"
};

export const programmaticPostSourcesLabel = {
  [programmaticPostSources.GSC]: "Search Console",
  [programmaticPostSources.GONG]: "Gong",
  [programmaticPostSources.UPLOAD]: "Upload"
};

export const programmaticPostStatus = {
  READY_TO_GENERATE: "ready_to_generate",
  GENERATING: "generating",
  GENERATED: "generated"
};

export const programmaticPostStatusLabel = {
  [programmaticPostStatus.READY_TO_GENERATE]: "Ready to Generate",
  [programmaticPostStatus.GENERATING]: "Generating",
  [programmaticPostStatus.GENERATED]: "Generated"
};

export const hubspotConnectionTypes = {
  BLOG: "blog",
  CRM: "crm"
};

export const pipelineAccountsFilter = {
  ALL: "all",
  SOURCED_PIPELINE: "sourced_pipeline",
  ACCELERATED_PIPELINE: "accelerated_pipeline",
  CLOSED_REVENUE: "closed_revenue",
  DEALS: "Deals"
};

export const MINIMUM_SESSION_DURATION = 30; // in seconds

export const readTimeTypes = {
  NUMBER: "number",
  STRING: "string"
};

export const pathOptions = {
  CONTAINS: "contains",
  DOES_NOT_CONTAIN: "does_not_contain",
  EQUALS: "equals",
  DOES_NOT_EQUAL: "does_not_equal"
};

export const pathOptionsLabel = {
  [pathOptions.CONTAINS]: "contains",
  [pathOptions.DOES_NOT_CONTAIN]: "does not contain",
  [pathOptions.EQUALS]: "equals",
  [pathOptions.DOES_NOT_EQUAL]: "does not equal"
};

export const accountActivityTypes = {
  DEAL_ACTIVITY: "DEAL_ACTIVITY",
  SALES_CALL_ACTIVITY: "SALES_CALL_ACTIVITY",
  LINKEDIN_ACTIVITY: "LINKEDIN_ACTIVITY",
  LINKEDIN_FOLLOWER_ACTIVITY: "LINKEDIN_FOLLOWER_ACTIVITY",
  LINKEDIN_CONNECTION_ACTIVITY: "LINKEDIN_CONNECTION_ACTIVITY",
  WEB_VISIT_ACTIVITY: "WEB_VISIT_ACTIVITY"
};

type ApolloContact = {
  id: string;
  emailer_campaign_ids: string[];
};

export type LinkedInUser = {
  userId: string;
  jobTitle: string;
  activities: string;
  lastEngaged: string;
  companyLocationName: string;
  companyName: string;
  region: string;
  companySize: string;
  headline: string;
  image: string;
  name: string;
  vanityName: string;
  type: string;
  stage: string;
  amount: string;
  apolloContact?: ApolloContact;
};

export const accountSegmentTypes = {
  POST: "post",
  PATH: "path"
};

export const linkedinAutoLikesPostTimeTypes = {
  IN_THIRTY_MINUTES: "in_thirty_minutes",
  AFTER_TWO_HOURS: "after_two_hours"
};

export const modulesAccess = {
  CONTENT_OPERATIONS: "content_operations", // Planning navigation module
  SOCIAL: "social", // Social media module
  SEO: "seo" // Website navigation module
};

export const LINKEDIN_ALLOWED_IMAGES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp"
];

export const errorNotificationSource = {
  GHOST: "ghost",
  HUBSPOT: "hubspot",
  SHOPIFY: "shopify",
  WEBFLOW: "webflow",
  WORDPRESS: "wordpress",
  WEBHOOK: "webhook",
  LINKEDIN: "linkedin"
};

export const postHogFeatureFlags = {
  STYLE_GUIDE: "style_guide",
  OUTLINE_LINK_PAGES: "outline_link_pages"
};

export const peopleAlsoAskQuery = "people-also-ask";
export const decayingPagesQuery = "decaying-pages";

export enum SalesCallIntegrationEnum {
  GONG = "gong",
  FIREFLIES = "fireflies",
  CHORUS = "chorus",
  CLARI = "clari",
  GRAIN = "grain"
}

export const FACEBOOK_ALLOWED_DOMAINS = ["usecanopy"];

export const ipEnrichmentProviders = {
  PEOPLE_DATA_LABS: "peopledatalabs",
  CLEARBIT: "clearbit",
  "6_SENSE": "6sense"
};

export const imageExtensions = [
  ".jpg",
  ".jpeg",
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".png",
  ".gif",
  ".bmp",
  ".dib",
  ".webp",
  ".tiff",
  ".tif",
  ".ico",
  ".svg",
  ".heic",
  ".heif",
  ".avif"
];

export const CHROME_EXTENSION_URL =
  "https://chromewebstore.google.com/detail/social-selling/nlonjnnlhajlljffkifbfdkgkhgakjee";

export type Publication = {
  _id: string;
  name: string;
  socialSellingKeywords: string[];
};

export const salesCallUserTypes = {
  INTERNAL: "Internal",
  EXTERNAL: "External"
};

export const socialSellingRoleTypes = {
  PROSPECT: "prospect",
  COLLECT: "collect",
  CREATE: "create"
};
